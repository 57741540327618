<template>
    <div class="grid-wrapper">
        <div class="c-default-table__pagination">
            <p class="c-default-table__pagination-info">Page <span ref="currentPage"></span> of <span ref="totalPages"></span></p>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toPreviousPage"><i class="fas fa-chevron-left"></i></mercur-button>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toNextPage"><i class="fas fa-chevron-right"></i></mercur-button>
        </div>

        <ag-grid-vue class="ag-theme-balham c-order-table"
                     :columnDefs="columnDefs"
                     :rowModelType="rowModel"
                     :gridOptions="gridOptions"
                     :getRowHeight="getRowHeight"
                     :isFullWidthCell="isFullWidthCell"
                     :fullWidthCellRenderer="fullWidthCellRenderer"
                     :frameworkComponents="frameworkComponents"
                     :pagination="true"
                     :cacheBlockSize="10"
                     :paginationPageSize="10"
                     suppressPaginationPanel=true
                     @pagination-changed="onPaginationChanged"
                     @grid-ready="onGridReady"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import ComplaintRow from '@/components/elements/complaints/Row'

import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'

export default {
    name: 'ComplaintsTable',
    components: {
        AgGridVue,
    },
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            },
        },
        url: {
            default: null,
        },
    },
    data () {
        return {
            columnDefs: null,
            gridOptions: null,
            getRowHeight: null,
            isFullWidthCell: null,
            fullWidthCellRenderer: null,
            frameworkComponents: null,
            filterModel: {},
            rowModel: 'serverSide',
        }
    },
    watch: {
        url () {
            this.applyDataSource()
        },
    },
    methods: {
        onGridReady () {
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            this.applyDataSource()
        },
        applyDataSource () {
            this.gridApi.setSortModel([{
                colId: 'dateCreated', sort: 'desc',
            }])

            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    if (data.length === 0) {
                        this.gridApi.showNoRowsOverlay()
                    }
                    successCallback(data, data[0].totalRows)
                    this.finishJob(this.url)
                    setTimeout(() => {
                        this.gridApi.sizeColumnsToFit()
                    }, 10)
                },
            })

            this.onPaginationChanged()
            this.gridApi.setServerSideDatasource(this.serverSideDatasource)
        },
        onPaginationChanged () {
            if (this.gridApi) {
                this.$refs.currentPage.innerHTML = this.gridApi.paginationGetCurrentPage() + 1
                this.$refs.totalPages.innerHTML = this.gridApi.paginationGetTotalPages()
            }
        },
        toPreviousPage () {
            this.gridApi.paginationGoToPreviousPage()
        },
        toNextPage () {
            this.gridApi.paginationGoToNextPage()
        },
        onModelUpdated (params) {
            params.api.sizeColumnsToFit()
        },
        refreshGrid () {
            this.gridApi.purgeServerSideCache()
        },
        applyFilter (filter) {
            this.gridApi.setFilterModel(filter)
        },
        listen () {
            this.refreshGrid()
            this.gridApi.hideOverlay()
        },
    },
    beforeMount () {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                autoHeight: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    caseSensitive: true,
                },
            },
            headerHeight: 0,
        }
        this.columnDefs = [
            { headerName: 'Order Id', field: 'orderNumber' },
            { headerName: 'Order Date', field: 'dateCreated' },
            { headerName: 'Customer', field: 'billingInfo.address.firstName' },
            { headerName: 'Customer', field: 'billingInfo.address.lastName', hide: true },
            { headerName: 'Company', field: 'billingInfo.address.company' },
            { headerName: 'Payment Method', field: 'paymentMethod' },
            { headerName: 'Order Status', field: 'orderStatus' },
            { headerName: 'Order Total', field: 'totals.total' },
            { headerName: 'Shop', field: 'applicationName' },
            { headerName: 'Complaint Status', field: 'complaintStatus', hide: true },
            { headerName: 'Complaint Id', field: 'complaintId', hide: true },
            { headerName: 'Date From', field: 'dateFrom', hide: true },
            { headerName: 'Date To', field: 'dateTo', hide: true },
        ]
        this.getRowHeight = () => {
            return 180
        }
        this.frameworkComponents = { fullWidthCellRenderer: ComplaintRow }
        this.isFullWidthCell = rowNode => {
            return rowNode.data
        }
        this.fullWidthCellRenderer = 'fullWidthCellRenderer'
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi

        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}
</script>
