<template>
    <div class="c-order-row">
        <mercur-card v-if="params.data">
            <merchant-data-table class="c-standard-table--row-separated" style="min-height: auto;" :options="complaintOptions" :items="complaintData" :isNotApplicationBound="true"></merchant-data-table>
            <hr>

            <div>
                <merchant-data-table class="c-order-row__orderline-table" style="height: auto; min-height: auto;" :options="options" :items="rowData" ref="table"></merchant-data-table>
            </div>
        </mercur-card>
    </div>
</template>

<script>
import Vue from 'vue'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import StatusChip from '@/components/utils/StatusChip'
import Currency from '@/components/elements/Currency'

export default Vue.extend({
    name: 'ComplaintRow',
    components: { MerchantDataTable },
    data () {
        return {
            complaintOptions: {
                columns: {
                    'Order Id': {
                        field: 'orderNumber',
                        link: (value, data) => this.routeOrder(data.orderId, data.accountId, data.applicationId),
                    },
                    'Order Date': { field: 'orderDate' },
                    'Customer': {
                        field: 'billingInfo.address.firstName',
                        valueFormatter: (params) => {
                            return `${params.data.billingInfo.address.firstName} ${params.data.billingInfo.address.lastName}`
                        },
                        link: (value, data) => this.routeCustomer(data.accountId, data.applicationId),
                    },
                    'lastName': { field: 'billingInfo.address.lastName', hide: true },
                    'Company': { field: 'billingInfo.address.company' },
                    'Payment Method': { field: 'paymentMethod' },
                    'Order Status': { field: 'orderStatus' },
                    'Total': {
                        field: 'totals.total',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.params.data.currency,
                            }
                        },
                    },
                    'Shop': { field: 'applicationName' },
                },
                pagination: false,
                headerHeight: 20,
                customCellClass: 'c-standard-table__cell--dense',
                defaultColDef: { menuTabs: [] },
            },
            options: {
                columns: {
                    'Orderline Id': {
                        field: 'orderLineNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderLineComplaints',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                    orderLineId: data.orderLineId,
                                },
                            }
                        },
                    },
                    'Product Name': { field: 'productName' },
                    'Department': { field: 'complaintDepartment' },
                    'Description': { field: 'description', width: 300 },
                    'Costs': { field: 'complaintCosts.value' },
                    'Status': { field: 'complaintStatus', cellRendererFramework: StatusChip, width: 250 },
                },
                pagination: false,
                defaultColDef: { menuTabs: [] },
                rowClass: 'c-order-row__stripped-row',
            },
        }
    },
    methods: {
        routeOrder (orderId, accountId, applicationId) {
            return {
                name: 'OrderWrapper',
                params: { orderId, accountId, applicationId },
            }
        },
        routeCustomer (id, applicationId) {
            return {
                name: 'CustomerView',
                params: {
                    customerId: id,
                    applicationId: applicationId,
                },
            }
        },
    },
    computed: {
        rowData () {
            return [this.params.data]
        },
        complaintData () {
            const data = JSON.parse(JSON.stringify(this.params.data))
            data.totalRows = 1
            return [data]
        },
    },
})
</script>
