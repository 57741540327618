<template>
    <div class="pb-5">
        <v-title title="Complaints"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Complaints</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listComplaints')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter @change="applyFilter" :filterFields="filterFields" :dropdownFilters="dropdownFilters" :has-date-range="true"></table-search-filter>
                </mercur-card>

                <complaints-table :url="url" ref="table"></complaints-table>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>

    </div>
</template>

<script>

import CONFIG from '@root/config'
import ComplaintsTable from '@/components/elements/complaints/Table'
import TableSearchFilter from '@/components/elements/table/SearchFilter'

export default {
    name: 'ComplaintsView',
    components: { ComplaintsTable, TableSearchFilter },
    data () {
        return {
            filterFields: {
                'orderNumber': { 'label': 'orderId' },
                'complaintId': { 'label': 'complaintId', 'operator': 'equals' },
            },
            statusFilters: [
                {
                    'column': 'complaintStatus',
                    'label': 'Complaint Status',
                    'operator': 'equals',
                    'values': {
                        'New': 'NEW',
                        'Open': 'OPEN',
                        'Awaiting Supplier': 'AWAITING_SUPPLIER',
                        'Approved': 'APPROVED',
                        'Refunded': 'REFUNDED',
                        'Reprinted': 'REPRINTED',
                        'Rejected': 'REJECTED',
                        'Rejection Accepted': 'REJECTION_ACCEPTED',
                        'Archived': 'ARCHIVED',
                        'Awaiting Customer Service': 'AWAITING_CUSTOMER_SERVICE',
                    },
                },
            ],
        }
    },
    computed: {
        url () {
            let url = CONFIG.API.ROUTES.COMPLAINTS.OVERVIEW.replace('{agentId}', this.$store.state.auth.user.accountId)
            if (this.$route.name === 'ComplaintsMerchantQueue') {
                url += `?complaintStatus=AWAITING_CUSTOMER_SERVICE`
            }

            if (this.$route.name === 'ComplaintsNewQueue') {
                url += `?complaintStatus=NEW`
            }

            if (this.$route.params.complaintSection) {
                url += `?complaintSection=${this.$route.params.complaintSection}`
            }

            return url
        },
        dropdownFilters () {
            const filters = []

            if (this.$route.name === 'ComplaintsMerchantQueue' || this.$route.name === 'ComplaintsNewQueue') {
                return filters
            }

            return [...this.statusFilters, ...filters]
        },
    },
    methods: {
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
    },
}
</script>
