<template>
    <div>
        <div class="row">
            <div class="col">
                <form @submit.prevent="filter">
                    <div class="d-flex align-items-end">
                        <mercur-input class="flex-grow-1 mr-1" v-model="filterText" :clearable="false">Search</mercur-input>
                        <mercur-button class="btn btn-icon mb-3" type="submit"><i class="fas fa-search"></i></mercur-button>
                    </div>
                </form>
            </div>
            <div class="col" v-if="hasDateRange">
                <label>Date created</label>
                <mercur-date-picker v-model="dateRange" :range-picker="true" :ranges="adicionalRanges" autoApply>Date range</mercur-date-picker>
            </div>
            <template v-if="dropdownFilters.length">
                <div class="col" v-for="(filter, index) in dropdownFilters" :key="index">
                    <mercur-select v-bind="filter.attrs" @selected="dropdownFilter($event, filter)">
                        <template #label>{{ filter.label }}</template>
                        <option v-for="(value, key) in filter.values" :key="key" :value="value">{{ key }}</option>
                    </mercur-select>
                </div>
            </template>
            <div class="col" v-if="dropdownSortBy.length">
                <mercur-select data-e2e="sortByFilter" @selected="triggerSort">
                    <template #label>Sort by</template>
                    <option v-for="(value, key) in dropdownSortBy" :key="key" :value="value.label">{{ value.label }}</option>
                </mercur-select>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <mercur-chip class="bg-yellow text-white" v-for="(value, key) in appliedFilters" @delete="removeFilter(key)" :key="key" :deletable="true">{{ value.label }}: {{ value.filter }}</mercur-chip>
            </div>
            <div class="col-3 text-right">
                <mercur-button class="btn btn-primary" @click.native="clearFilters">Clear filters</mercur-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'TableSearchFilter',
    data () {
        return {
            filterText: '',
            appliedFilters: {},
            dateRange: {
                startDate: '',
                endDate: '',
            },
        }
    },
    props: {
        filterFields: {
            default: () => {
                return {}
            },
        },
        dropdownFilters: {
            default: () => {
                return []
            },
        },
        dropdownSortBy: {
            default: () => {
                return []
            },
        },
        hasDateRange: {
            default: false,
        },
        adicionalRanges: {
            default: false,
        },
    },
    watch: {
        appliedFilters: {
            handler: function () {
                this.$emit('change', this.appliedFilters)
            },
            deep: true,
        },
        dateRange (value) {
            this.$set(this.appliedFilters, 'dateFrom', {
                'filter': moment(value.startDate).utc().format('YYYY-MM-DD HH:mm:ssZ'),
                'filterType': 'text',
                'type': 'greaterThan',
                'label': 'Date From',
            })
            this.$set(this.appliedFilters, 'dateTo', {
                'filter': moment(value.endDate).utc().format('YYYY-MM-DD HH:mm:ssZ'),
                'filterType': 'text',
                'type': 'lessThan',
                'label': 'Date To',
            })
        },
    },
    methods: {
        removeFilter (key) {
            this.$delete(this.appliedFilters, key)
        },
        clearFilters () {
            this.appliedFilters = {}
        },
        filter () {
            const filtersToBeApplied = {}
            for (let key in this.filterFields) {
                filtersToBeApplied[key] = {
                    filter: this.filterText.toLowerCase(),
                    filterType: 'text',
                    type: this.filterFields[key].operator || 'contains',
                    label: this.filterFields[key].label,
                }
            }
            this.$set(this, 'appliedFilters', filtersToBeApplied)
            this.filterText = ''
        },
        dropdownFilter ($event, filter) {
            if (!$event) {
                return
            }
            this.$set(this.appliedFilters, filter.column, {
                'filter': $event,
                'filterType': 'text',
                'type': filter.operator || 'contains',
                'label': filter.label,
            })
        },
        triggerSort ($event) {
            const data = this.dropdownSortBy.filter((e) => e.label === $event)
            this.$emit('sort', data)
        },
    },

}
</script>

<style lang="scss" scoped>
    .c-search-button {
        height: auto;
        min-width: unset;
    }
</style>
